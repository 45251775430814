<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-row>
        <v-tabs color="success">
          <v-tab>Адресний відбір</v-tab>
          <v-tab>Налаштування</v-tab>
          <v-tab :disabled="!removal_by_item">Статті тарифу</v-tab>
          <v-tab :disabled="!set_flats">Абоненти</v-tab>
          <v-tab>Виключення</v-tab>

          <v-tab-item>
            <v-form v-model="formValid" ref="form">
              <v-row class="pt-5 pb-2 px-2 dense-wrapper">
                <v-col cols="6" class="pt-0">
                  <v-switch
                      class="mt-1"
                      v-model="all_flats"
                      hide-details
                      :disabled="set_flats"
                      label="Усі абоненти організації"
                      @change="onAllFlatOrSetFlatsChange"
                      color="secondary"/>
                </v-col>
                <v-col cols="6" class="pt-0">
                  <v-switch
                      class="mt-1"
                      v-model="set_flats"
                      hide-details
                      :disabled="all_flats"
                      @change="onAllFlatOrSetFlatsChange"
                      label="Вказати перелік абонентів"
                      color="secondary"/>
                </v-col>
                <v-col cols="12">
                  <AddressElementSelect v-model="city_id"
                                        label="Населений пункт" filled
                                        select_type="city"
                                        :disabled="all_flats || set_flats"
                  />
                </v-col>
                <v-col cols="12">
                  <AddressElementSelect :parent_id="city_id" v-model="streets"
                                        label="Вулиця(-ки)" multiple filled
                                        select_type="street" use_parent_id
                                        :disabled="!city_id || all_flats || set_flats"
                  />
                </v-col>
                <v-col cols="12">
                  <AddressElementSelect :parent_id="streets" v-model="buildings"
                                        label="Будинок(-ки)" multiple filled
                                        select_type="building" use_parent_id
                                        :disabled="!streets.length || all_flats || set_flats"
                  />
                </v-col>
                <v-col cols="12" v-if="!is_legacy">
                  <v-switch
                      class="mt-1"
                      v-model="entrance_filter"
                      hide-details
                      :disabled="set_flats"
                      label="Відбір по підїзду"
                      color="secondary"/>
                </v-col>
                <v-col cols="6" v-if="!is_legacy">
                  <v-text-field v-model="entrance_filter_from" hide-details filled
                                color="grey" required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                :class="entrance_filter_from ? '' : entrance_filter ? 'req-star' : ''"
                                :disabled="!entrance_filter"
                                label="Поверх з"/>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="entrance_filter_to" hide-details filled
                                color="grey" required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                :class="entrance_filter_to ? '' : entrance_filter ? 'req-star' : ''"
                                :disabled="!entrance_filter"
                                label="Поверх по"/>
                </v-col>
                <v-col cols="12" v-if="!is_legacy">
                  <v-switch
                      class="mt-1"
                      v-model="floor_filter"
                      hide-details
                      :disabled="set_flats"
                      label="Відбір по поверху"
                      color="secondary"/>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="floor_filter_from" hide-details filled
                                color="grey" required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                :class="floor_filter_from ? '' : floor_filter ? 'req-star' : ''"
                                :disabled="!floor_filter"
                                label="Поверх з"/>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="floor_filter_to" hide-details filled
                                color="grey" required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                :class="floor_filter_to ? '' : floor_filter ? 'req-star' : ''"
                                :disabled="!floor_filter"
                                label="Поверх по"/>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

          <v-tab-item>
            <v-row class="pt-5 pb-2 px-2 dense-wrapper">
              <v-col cols="6">
                <v-switch
                    class="mt-1"
                    v-model="removal_by_item"
                    hide-details
                    label="Зняття по статтях"
                    color="secondary"/>
              </v-col>
              <v-col cols="6">
                <v-switch
                    class="mt-1"
                    v-model="always_removal"
                    hide-details
                    label="Постійне зняття"
                    color="secondary"/>
              </v-col>
              <v-col cols="12">
                <v-select :items="services" filled label="Послуга"
                          hide-details
                          v-model="service_id"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="service_id ? '' : 'req-star'"
                          color="grey"
                />
              </v-col>
              <v-col cols="12">
                <AC_TariffGroup :value="tariff_group_type_id" @autocompleteChange="autocompleteChange"/>
              </v-col>
              <v-col cols="6">
                <date-component v-model="date_start" req label="Період з"/>
              </v-col>
              <v-col cols="6">
                <date-component v-model="date_end" label="Період по"/>
              </v-col>
              <v-col cols="6" v-if="!removal_by_item">
                <v-text-field v-model="removal_tariff" hide-details filled
                              color="grey"
                              label="Тариф зняття"/>
              </v-col>
              <v-col cols="6" v-if="!removal_by_item">
                <v-text-field v-model="removal_sum" hide-details filled
                              color="grey"
                              label="Сума зняття"/>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item :disabled="!removal_by_item">
            <div class="form-table-custom mt-3 pb-2 px-2 dense-wrapper">
              <div class="table-command-panel mb-3">
                <v-btn plain small class="grey lighten-3 mr-2" @click.stop="addRemovalItem">
                  <v-icon small color="success" left>mdi-plus</v-icon>
                  Додати
                </v-btn>
                <v-btn plain small class="grey lighten-3"
                       @click="onRemovalItemDelete"
                >
                  <v-icon small color="error" left>mdi-delete</v-icon>
                  Вилучити
                </v-btn>
              </div>
              <table>
                <thead>
                <tr>
                  <th style="width: 30px;"></th>
                  <th style="min-width: 70px;">№ п/п</th>
                  <th style="min-width: 400px;">Стаття тарифу</th>
                  <th style="min-width: 70px;">Тариф</th>
                  <th style="min-width: 70px;">Сума</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, idx) in removal_items" :key="`entrance-${idx}`">
                  <td style="width: 30px">
                    <v-checkbox hide-details class="ml-2 mt-0" :ripple="false" color="secondary"
                                :value="selected_removal_item.includes(item)"
                                @change="onRemovalItemSelect(item)"
                    />
                  </td>
                  <td style="min-width: 70px;">
                    <input type="text" v-integer disabled :value="idx + 1">
                  </td>
                  <td style="min-width: 400px;">
                    <AC_RemovalItem :value="item.removal_item_id"
                                    class="pt-0 mt-0"
                                    :row_num="idx" required
                                    clearable
                                    :tile="true"
                                    :filled="false"
                                    color=""
                                    label=""
                                    :style_="'font-size: 13px !important; padding-left: 6px !important; height: 100%; padding-left: 0'"
                                    @autocompleteChange="onRemovalItemChange"
                    />
                  </td>
                  <td style="min-width: 70px;">
                    <input type="text"
                           v-decimal
                           v-model.number="item.tariff">
                  </td>
                  <td style="min-width: 70px;">
                    <input type="text"
                           v-decimal
                           v-model.number="item.sum">
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </v-tab-item>

          <v-tab-item :disabled="!set_flats">
            <div class="form-table-custom mt-3 pb-2 px-2">
              <div class="table-command-panel mb-3">
                <v-btn plain small class="grey lighten-3 mr-2" @click.stop="addFlatItem">
                  <v-icon small color="success" left>mdi-plus</v-icon>
                  Додати
                </v-btn>
                <v-btn plain small class="grey lighten-3" @click="onFlatDelete"
                >
                  <v-icon small color="error" left>mdi-delete</v-icon>
                  Вилучити
                </v-btn>
              </div>
              <table>
                <thead>
                  <tr>
                    <th style="width: 30px;"></th>
                    <th style="min-width: 70px;">№ п/п</th>
                    <th style="min-width: 400px;">Абонент</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, idx) in flats" :key="`entrance-${idx}`">
                    <td style="width: 30px">
                      <v-checkbox hide-details class="ml-2 mt-0" :ripple="false" color="secondary"
                                  :value="selected_flats.includes(item)"
                                  @change="onFlatItemSelect(item)"
                      />
                    </td>
                    <td style="min-width: 70px;">
                      <input type="text" v-integer disabled :value="idx + 1">
                    </td>
                    <td style="min-width: 400px" v-if="!is_legacy">
                        <AUC_FlatSearch
                                :row_num="item.row_num"
                                :flat_id="item.flat_id"
                                :text_value="item.address"
                                :text_value_key="'full_address'"
                                :input_style="'width: 99% !important;'"
                                :class="{'error-input': !item.flat_id}"
                                @selectChange="flatChange"
                        />
                    </td>
                    <td style="min-width: 400px" v-else>
                        <AUC_FlatSearchLegacy
                                :row_num="item.row_num"
                                :flat_id="item.flat_id"
                                :text_value="item.address"
                                :text_value_key="'contractor_name_full'"
                                :class="{'error-input': !item.flat_id}"
                                @selectChange="flatChange"
                        />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="form-table-custom mt-3 pb-2 px-2">
              <div class="table-command-panel mb-3">
                <v-btn plain small class="grey lighten-3 mr-2" @click.stop="addFlatExcludeItem">
                  <v-icon small color="success" left>mdi-plus</v-icon>
                  Додати
                </v-btn>
                <v-btn plain small class="grey lighten-3" @click="onFlatExcludeDelete"
                >
                  <v-icon small color="error" left>mdi-delete</v-icon>
                  Вилучити
                </v-btn>
              </div>
              <table>
                <thead>
                <tr>
                  <th style="width: 30px;"></th>
                  <th style="min-width: 70px;">№ п/п</th>
                  <th style="min-width: 400px;">Абонент</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, idx) in flats_exclude" :key="`entrance-${idx}`">
                  <td style="width: 30px">
                    <v-checkbox hide-details class="ml-2 mt-0" :ripple="false" color="secondary"
                                :value="selected_flats_exclude.includes(item)"
                                @change="onFlatExcludeItemSelect(item)"
                    />
                  </td>
                  <td style="min-width: 70px;">
                    <input type="text" v-integer disabled :value="idx + 1">
                  </td>
                  <td style="min-width: 400px" v-if="!is_legacy">
                      <AUC_FlatSearch
                              :row_num="item.row_num"
                              :flat_id="item.flat_id"
                              :text_value="item.address"
                              :text_value_key="'full_address'"
                              :input_style="'width: 99% !important;'"
                              :class="{'error-input': !item.flat_id}"
                              @selectChange="flatExcludeChange"
                      />
                  </td>
                  <td style="min-width: 400px" v-else>
                      <AUC_FlatSearchLegacy
                              :row_num="item.row_num"
                              :flat_id="item.flat_id"
                              :text_value="item.address"
                              :text_value_key="'contractor_name_full'"
                              :class="{'error-input': !item.flat_id}"
                              @selectChange="flatChange"
                      />
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1"
             @click.stop="updateService" class="button-accept" :loading="getModalLoading"
             :disabled="!check_form_ready"
      >
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import {SELECT_SERVICES} from "@/store/actions/services";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

import {maxBy} from "lodash";
import {CREATE_REMOVAL_ITEMS, REMOVE_REMOVAL_ITEMS, UPDATE_REMOVAL_ITEMS} from "@/store/actions/removal";

const modalDeleteId = 'removal_modal_delete'

export default {
  name: "HWP_Modal_Removal",
  mixins: [ModalComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    AC_TariffGroup: () => import("@/components/autocomplite/AC_TariffGroup"),
    AUC_FlatSearch: () => import("@/components/tableComponent/AUC_FlatSearch.vue"),
    AUC_FlatSearchLegacy: () => import("@/components/tableComponent/AUC_FlatSearchLegacy.vue"),
    AC_RemovalItem: () => import("@/components/autocomplite/Removaltem")
  },
  data() {
    return {
      is_legacy: this.item.is_legacy || false,
      removal_by_item: this.item.removal_by_item || false,
      always_removal: this.item.always_removal || false,
      all_flats: this.item.all_flats || false,
      set_flats: this.item.set_flats || false,
      city_id: this.item.city_id || null,
      streets: this.item.streets || [],
      buildings: this.item.buildings || [],
      service_id: this.item.service_id || null,
      date_start: this.item.date_start || null,
      date_end: this.item.date_end || null,
      tariff_group_type_id: this.item.tariff_group_type_id || null,
      floor_filter: this.item.floor_filter || false,
      floor_filter_from: this.item.floor_filter_from || null,
      floor_filter_to: this.item.floor_filter_to || null,
      removal_items: this.item.removal_items || [],
      entrance_filter: this.item.entrance_filter || false,
      entrance_filter_from: this.item.entrance_filter_from || null,
      entrance_filter_to: this.item.entrance_filter_to || null,
      flats: this.item.flats || [],
      flats_exclude: this.item.flats_exclude || [],
      removal_tariff: this.item.removal_tariff || null,
      removal_sum: this.item.removal_sum || null,

      selected_removal_item: [],
      selected_flats: [],
      selected_flats_exclude: [],
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll',
    }),
    check_form_ready() {

      if (!this.all_flats) {
        if (!this.city_id && !this.streets.length && !this.buildings.length && !this.set_flats) {
          return false
        }
      }
      if (this.floor_filter) {
        if (!this.floor_filter_from || !this.floor_filter_to) {
          return false
        }
      }
      if (!this.service_id) {
        return false
      }

      if (!this.date_start) {
        return false
      }
      if (!this.removal_by_item) {
        if (!this.removal_sum && !this.removal_tariff) {
          return false
        }
      }
      if (this.set_flats) {
        if (!this.flats.length) {
          return false
        } else {
          if (this.flats.filter(item => !item.flat_id).length) {
            return false
          }
        }
      }
      if (this.removal_by_item) {
        if (!this.removal_items.length) {
          return false
        } else {
          if (this.removal_items.filter(item => !item.removal_item_id).length) {
            return false
          }
        }
      }
      if (this.flats_exclude.length) {
        if (this.flats_exclude.filter(item => !item.flat_id).length) {
          return false
        }
      }
      return true
    },
  },
  methods: {
    ...mapActions({
      fetchServices: SELECT_SERVICES,
    }),
    check_form() {
      const result = {
        ready: true,
        error: []
      }
      if (!this.all_flats) {
        if (!this.city_id && !this.streets.length && this.buildings.length) {
          result.ready = false
          result.error.push('Вкажіть населений пункт та/або вулиці та або будинки')
        }
      }
      if (this.floor_filter) {
        if (!this.floor_filter_from || !this.floor_filter_to) {
          result.ready = false
          result.error.push('Поверх від або поверх до - мають бути заповнені')
        }
      }
      if (this.entrance_filter) {
        if (!this.entrance_filter_from || !this.entrance_filter_to) {
          result.ready = false
          result.error.push('Підїзд від або підїзд до - мають бути заповнені')
        }
      }
      if (!this.service_id) {
        result.ready = false
        result.error.push('Вкажіть послугу по якій виконується зняття')
      }

      if (!this.date_start) {
        result.ready = false
        result.error.push('Вкажіть дату початку')
      }
      if (!this.removal_by_item) {
        if (!this.removal_sum && !this.removal_tariff) {
          result.ready = false
          result.error.push('Вкажіть суму або тариф зняття')
        }
      }
      if (this.set_flats) {
        if (!this.flats.length) {
          result.ready = false
          result.error.push('Вкажіть перелік абонентів/квартир для проведення зняття')
        } else {
          if (this.flats.filter(item => !item.flat_id).length) {
            result.ready = false
            result.error.push('Ви не вказали абонента у таблиці абонентів')
          }
        }
      }
      if (this.removal_by_item) {
        if (!this.removal_items.length) {
          result.ready = false
          result.error.push('Вкажіт перелік статей зняття, тариф або суму')
        } else {
          if (this.removal_items.filter(item => !item.removal_item_id).length) {
            result.ready = false
            result.error.push('Ви не вказали статтю витрат у таблиці статей витрат')
          }
        }

      }
      if (this.flats_exclude.length) {
        if (this.flats_exclude.filter(item => !item.flat_id).length) {
          result.ready = false
          result.error.push('Ви не вказали абонента у таблиці виключень абонентів')
        }
      }
      return result
    },
    onRemovalItemChange(payload) {
      const row_num = (payload || {}).row_num
      if (row_num !== null && row_num !== undefined) {
        this.removal_items[row_num].removal_item_id = payload.value || null
      }
    },
    autocompleteChange(payload) {
      this.tariff_group_type_id = payload.value || null
    },
    onAllFlatOrSetFlatsChange() {
      this.$nextTick(() => {
        if (this.all_flats) {
          this.set_flats = false
        }
        if (this.set_flats) {
          this.all_flats = false
        }
      })
    },
    addRemovalItem() {
      this.removal_items.push(
          {
            removal_item_id: null,
            tariff: 0,
            sum: 0
          }
      )
    },
    addFlatItem() {
      const max_row_item = maxBy(this.flats, 'row_num')

      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }
      this.flats.push(
          {
            flat_id: null,
            address: null,
            row_num: max_row + 1
          }
      )
    },
    addFlatExcludeItem() {
      const max_row_item = maxBy(this.flats_exclude, 'row_num')

      let max_row = 0
      if (max_row_item) {
        max_row = max_row_item.row_num
      }
      this.flats_exclude.push(
          {
            flat_id: null,
            address: null,
            row_num: max_row + 1
          }
      )
    },
    flatChange(payload) {
      const current_row = this.flats.find(item => item.row_num === payload.row_num)
      if (current_row) {
        current_row.flat_id = payload.value
          if (payload.contractor_name) {
              current_row.address = `${payload.person_id}, ${payload.contractor_name}`
          } else {
              current_row.address = payload.address || ''
          }
      }
    },
    flatExcludeChange(payload) {
      const current_row = this.flats_exclude.find(item => item.row_num === payload.row_num)
      if (current_row) {
        current_row.flat_id = payload.value
        current_row.address = payload.address || ''
      }
    },
    onRemovalItemSelect(payload) {
      if (this.selected_removal_item.includes(payload)) {
        this.selected_removal_item.splice(this.selected_removal_item.indexOf(payload), 1)
      } else {
        this.selected_removal_item.push(payload)
      }
    },
    onFlatItemSelect(payload) {
      if (this.selected_flats.includes(payload)) {
        this.selected_flats.splice(this.selected_flats.indexOf(payload), 1)
      } else {
        this.selected_flats.push(payload)
      }
    },
    onFlatExcludeItemSelect(payload) {
      if (this.selected_flats_exclude.includes(payload)) {
        this.selected_flats_exclude.splice(this.selected_flats_exclude.indexOf(payload), 1)
      } else {
        this.selected_flats_exclude.push(payload)
      }
    },
    onRemovalItemDelete() {
      if (!this.selected_removal_item.length) return
      this.selected_removal_item.forEach(item => {
        this.removal_items.splice(this.removal_items.indexOf(item), 1)
      })
      this.selected_removal_item = []
    },
    onFlatDelete() {
      if (!this.selected_flats.length) return
      this.selected_flats.forEach(item => {
        this.flats.splice(this.flats.indexOf(item), 1)
      })
      this.selected_floor = []
    },
    onFlatExcludeDelete() {
      if (!this.selected_flats_exclude.length) return
      this.selected_flats_exclude.forEach(item => {
        this.flats_exclude.splice(this.flats_exclude.indexOf(item), 1)
      })
      this.selected_floor = []
    },
    onCityChange(payload) {
      this.city_id = payload.value
    },
    onStreetChange(payload) {
      this.streets = payload.map(item => item.value) || []
    },
    onBuildingChange(payload) {
      this.buildings = payload.map(item => item.value) || []
    },
    closeModal(payload=null) {
      this.$emit('closeModal', payload)
      this.itemId = this.item.id
      this.is_legacy = this.item.is_legacy || false
      this.removal_by_item = this.item.removal_by_item || false
      this.always_removal = this.item.always_removal || false
      this.all_flats = this.item.all_flats || false
      this.set_flats = this.item.set_flats || false
      this.city_id = this.item.city_id || null
      this.streets = this.item.streets || []
      this.buildings = this.item.buildings || []
      this.service_id = this.item.service_id || null
      this.date_start = this.item.date_start || null
      this.date_end = this.item.date_end || null
      this.tariff_group_type_id = this.item.tariff_group_type_id || null
      this.floor_filter = this.item.floor_filter || false
      this.floor_filter_from = this.item.floor_filter_from || null
      this.floor_filter_to = this.item.floor_filter_to || null
      this.entrance_filter = this.item.entrance_filter || false
      this.entrance_filter_from = this.item.entrance_filter_from || null
      this.entrance_filter_to = this.item.entrance_filter_to || null
      this.removal_items = this.item.removal_items || []
      this.flats = this.item.flats || []
      this.flats_exclude = this.item.flats_exclude || []
      this.removal_tariff = this.item.removal_tariff || null
      this.removal_sum = this.item.removal_sum || null

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення зняття: ${this.getServiceName()}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    updateService() {
      this.formValid = true
      this.$refs.form.validate()
      const form_check = this.check_form()
      this.formValid = form_check.ready

      if (!form_check.ready) {
        this.$store.commit(ALERT_SHOW, {text: form_check.error.join('; '), color: 'error'})
        return
      }

      const payload = {
        removal_by_item: this.removal_by_item,
        always_removal: this.always_removal,
        all_flats: this.all_flats,
        set_flats: this.set_flats,
        city_id: this.city_id,
        streets: this.streets,
        buildings: this.buildings,
        service_id: this.service_id,
        date_start: this.date_start,
        date_end: this.date_end,
        tariff_group_type_id: this.tariff_group_type_id,
        floor_filter: this.floor_filter || false,
        floor_filter_from: this.floor_filter_from,
        floor_filter_to: this.floor_filter_to,
        entrance_filter: this.entrance_filter,
        entrance_filter_from: this.entrance_filter_from,
        entrance_filter_to: this.entrance_filter_to,
        removal_items: this.removal_items,
        flats: this.flats,
        flats_exclude: this.flats_exclude,
        removal_tariff: this.removal_tariff,
        removal_sum: this.removal_sum,
        is_Legacy: this.is_legacy
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_REMOVAL_ITEMS, payload)
          .then(() => {
            this.closeModal()
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
      } else {
        payload['id'] = this.itemId
        this.$store.dispatch(UPDATE_REMOVAL_ITEMS, payload)
          .then(() => {
            this.closeModal()
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
      }
    },
    getServiceName() {
      if (this.service_id) {
        const ind = this.services.find(item => item.value === this.service_id)
        if (ind) {
          return ind.text
        }
      }
      return ''
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.is_legacy = payload.is_legacy || false
              this.removal_by_item = payload.removal_by_item || false
              this.always_removal = payload.always_removal || false
              this.all_flats = payload.all_flats || false
              this.set_flats = payload.set_flats || false
              this.city_id = payload.city_id || null
              this.streets = payload.streets || []
              this.buildings = payload.buildings || []
              this.service_id = payload.service_id || null
              this.date_start = payload.date_start || null
              this.date_end = payload.date_end || null
              this.tariff_group_type_id = payload.tariff_group_type_id || null
              this.floor_filter = payload.floor_filter || false
              this.floor_filter_from = payload.floor_filter_from || null
              this.floor_filter_to = payload.floor_filter_to || null
              this.entrance_filter = payload.entrance_filter || false
              this.entrance_filter_from = payload.entrance_filter_from || null
              this.entrance_filter_to = payload.entrance_filter_to || null
              this.removal_items = payload.removal_items || []
              this.flats = payload.flats || []
              this.flats_exclude = payload.flats_exclude || []
              this.removal_tariff = payload.removal_tariff || null
              this.removal_sum = payload.removal_sum || null
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_REMOVAL_ITEMS, this.itemId)
                    .then(() => {
                      this.closeModal()
                    })
                    .catch(err => {
                      const error = err.response.data.detail;
                      this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  created() {
    this.fetchServices()
  }
}
</script>
